<template>
  <v-form
    ref="form"
    v-model="valid"
    :lazy-validation="lazy"
  >
    <v-container>
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <v-card class="my-8">
            <v-card-title>기본정보 / {{ this.$route.params.idx }}</v-card-title>
            <v-list class="pa-4">
              <v-text-field
                v-model.trim="model.formItem.name"
                label="간병인명"
                :rules="[rules.required]"
                outlined
                dense
                hide-details="auto"
              />
            </v-list>
            <v-list class="pa-4">
              <v-text-field
                v-model.trim="model.formItem.phone_number"
                label="연락처"
                :rules="[rules.required]"
                outlined
                dense
                hide-details="auto"
              />
            </v-list>
            <v-list class="pa-4">
              <v-text-field
                v-model="model.formItem.age"
                label="나이"
                suffix="세"
                :rules="[rules.required]"
                type="number"
                outlined
                dense
                hide-details="auto"
              />
            </v-list>
            <v-list class="pa-4">
              <v-radio-group
                v-model="model.formItem.gender"
                hide-details="auto"
                row
                class="mt-0"
              >
                <div class="subtitle-1 pr-4">
                  성별
                </div>
                <v-radio
                  label="남"
                  value="male"
                  on-icon="radio_button_checked"
                  off-icon="radio_button_unchecked"
                />
                <v-radio
                  label="여"
                  value="female"
                  on-icon="radio_button_checked"
                  off-icon="radio_button_unchecked"
                />
              </v-radio-group>
            </v-list>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-card class="my-8">
            <v-card-title>부가정보</v-card-title>
            <v-list class="pa-4">
              프로필 이미지
              <FilePond
                ref="pond1"
                name="upload1"
                label-idle="Drop files here..."
                :allow-multiple="false"
                accepted-file-types="image/jpeg, image/png"
                :server="null"
                :instant-upload="false"
                :files="uploadFile"
                @init="handleFilePondInit"
              />
            </v-list>
            <v-list class="pa-4">
              <v-text-field
                v-model="model.formItem.career"
                label="경력"
                suffix="년"
                type="number"
                outlined
                dense
                hide-details="auto"
              />
            </v-list>
            <v-list class="pa-4">
              <v-text-field
                v-model.trim="model.formItem.license"
                label="관련 자격증"
                outlined
                dense
                hide-details="auto"
              />
            </v-list>
            <v-list class="pa-4">
              <v-textarea
                v-model="model.formItem.introduce"
                label="자기소개"
                :rules="[rules.required]"
                outlined
                dense
                hide-details="auto"
              />
            </v-list>
          </v-card>
        </v-col>

        <v-col
          cols="12"
          sm="6"
        >
          <v-card class="my-8">
            <v-card-title>활동 정보</v-card-title>
            <v-list class="pa-4">
              활동 지역
              <div class="d-flex pt-4">
                <div>
                  <v-select
                    ref="item__working_area_idx"
                    v-model="model.formItem.working_area_idx"
                    :items="regions"
                    label="시/도"
                    item-text="short_name"
                    :item-value="(item) => item.idx"
                    append-icon="expand_more"
                    dense
                    outlined
                    hide-details="auto"
                    @input="onChangeRegion"
                  />
                </div>
                <div class="ml-4">
                  <v-select
                    ref="item__working_area_detail_idx"
                    v-model="model.formItem.working_area_detail_idx"
                    :items="cities"
                    label="시/군/구"
                    item-text="short_name"
                    :item-value="(item) => item.idx"
                    append-icon="expand_more"
                    dense
                    outlined
                    hide-details="auto"
                  />
                </div>
              </div>
            </v-list>
            <v-list class="pa-4">
              <v-select
                ref="item__desired_salary"
                v-model="model.formItem.desired_salary"
                :items="desiredSalaryItems"
                label="희망 급여"
                append-icon="expand_more"
                dense
                outlined
                hide-details="auto"
              />
            </v-list>
            <v-list class="pa-4">
              <v-select
                ref="item__office_hour"
                v-model="model.formItem.office_hour"
                :items="officeHourItems"
                label="근무시간"
                append-icon="expand_more"
                item-text="title"
                item-value="value"
                dense
                outlined
                hide-details="auto"
              />
            </v-list>

            <v-list class="pa-4">
              <v-radio-group
                v-model="model.formItem.marketing_notification"
                hide-details="auto"
                row
                class="mt-0"
              >
                <div class="subtitle-1 pr-4">
                  마케팅 동의여부
                </div>
                <v-radio
                  label="동의"
                  :value="1"
                  on-icon="radio_button_checked"
                  off-icon="radio_button_unchecked"
                />
                <v-radio
                  label="거부"
                  :value="0"
                  on-icon="radio_button_checked"
                  off-icon="radio_button_unchecked"
                />
              </v-radio-group>
            </v-list>

            <v-list class="pa-4">
              <v-radio-group
                v-model="model.formItem.event_notification"
                hide-details="auto"
                row
                class="mt-0"
              >
                <div class="subtitle-1 pr-4">
                  이벤트 동의여부
                </div>
                <v-radio
                  label="동의"
                  :value="1"
                  on-icon="radio_button_checked"
                  off-icon="radio_button_unchecked"
                />
                <v-radio
                  label="거부"
                  :value="0"
                  on-icon="radio_button_checked"
                  off-icon="radio_button_unchecked"
                />
              </v-radio-group>
            </v-list>
          </v-card>
        </v-col>
        <v-col
          v-show="mode !== 'add'"
          cols="12"
          sm="6"
        >
          <v-card class="my-8">
            <v-card-title>설정 정보</v-card-title>
            <v-list class="pa-4">
              <div>
                알람 설정 여부
                <v-chip
                  label
                  small
                  class="mx-2"
                >
                  {{ setAlarmStatus }}
                </v-chip>
              </div>
            </v-list>
          </v-card>
        </v-col>
      </v-row>

      <v-row
        justify="center"
        class="mt-5"
      >
        <v-col
          cols="auto"
        >
          <v-btn
            v-if="mode==='add'"
            class="mx-4"
            color="primary"
            @click="addItem"
          >
            <v-icon small>
              add
            </v-icon>
            <span class="pl-2">등록</span>
          </v-btn>

          <v-btn
            v-if="mode==='modify'"
            class="mx-4"
            color="primary"
            @click="updateItem"
          >
            <v-icon small>
              edit
            </v-icon>
            <span class="pl-2">수정</span>
          </v-btn>
          <v-btn
            v-if="mode==='modify'"
            class="mx-4"
            color="error"
            @click="deleteConfirmSheetView = !deleteConfirmSheetView"
          >
            <v-icon small>
              delete
            </v-icon>
            <span class="pl-2">삭제</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-bottom-sheet
      v-model="deleteConfirmSheetView"
      persistent
    >
      <v-sheet
        class="text-center"
        height="250px"
      >
        <div class="py-3">
          정말 삭제하시겠습니까?
          <v-btn
            class="mx-3"
            color="primary"
            @click="deleteItem"
          >
            <span>Y</span>
          </v-btn>
          <v-btn
            class="mx-3"
            color=""
            @click="deleteConfirmSheetView = !deleteConfirmSheetView"
          >
            <span>N</span>
          </v-btn>
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </v-form>
</template>
<script>
// Import FilePond styles
import "filepond/dist/filepond.min.css"

// Import image preview plugin styles
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css"

// Import FilePond plugins
// Please note that you need to install these plugins separately
// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type"
import FilePondPluginImagePreview from "filepond-plugin-image-preview"

// Import Vue FilePond
import vueFilePond from "vue-filepond"

// import Carer from './model/carer'
import { CarerSchema, CarerFormSchema } from './model/carerSchema'
import { getAddressList } from '../address/model/addressFinder'
import FileUploadApi from '../_common/fileUploadApi'
// import Api from './model/api'

// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
)

export default {
  components: {
    FilePond
  },
  // eslint-disable-next-line vue/prop-name-casing
  props: ['value', 'working_area_idx', 'working_area_detail_idx'],
  data() {
    return {
      model: null,
      desiredSalaryItems: ['가격협의', '1만원', '2만원', '3만원', '4만원', '5만원', '6만원', '7만원', '9만원', '10만원', '11만원', '12만원', '13만원', '14만원', '15만원', '16만원', '17만원', '18만원', '19만원', '20만원'],
      officeHourItems: [{ title: '무관', value: 'anytime' }, { title: '24시간', value: 'allday' }, { title: '주간12시간', value: 'daytime' }, { title: '야간12시간', value: 'nighttime' }],
      valid: false,
      lazy: false,
      deleteConfirmSheetView: false,
      mode: '',
      mode_text: '',
      uploadFile: [],
      item: new CarerSchema(),
      formItem: new CarerFormSchema(),
      regions: [],
      cities: [],
      setAlarmStatus: '',
      rules: {
        required: (value) => !!value || '꼭 입력해주세요'
      }
    }
  },
  watch: {
    async working_area_idx() {
      await this.onChangeRegion()
    }
  },
  async created() {
    await this.setup()
  },
  methods: {
    async setup() {
      this.model = this.value
      const { idx } = this.$route.params
      await this.getRegions()
      if (idx === 'add') {
        this.mode = 'add'
        this.mode_text = '등록'
      } else if (this.$route.name === 'Carer.Item') {
        this.mode = 'modify'
        this.mode_text = '확인, 수정'
        await this.onChangeRegion()
        this.setAlarmStatus = await this.model.isSetAlarm()
        if (this.model.formItem.profile_image) {
          this.uploadFile = [await this.attachCurrentTime(this.model.formItem.profile_image)]
        }
      }
    },
    async attachCurrentTime(filename) {
      return `${filename}?${(new Date()).getTime()}`
    },
    handleFilePondInit() {
    },
    async uploadImage() {
      const files = this.$refs.pond1.getFiles()

      if (files.length === 0) {
        // this.$bus.$emit('warningMessage', '등록할 이미지가 없습니다')
        return ''
      }

      const formData = new FormData()
      formData.append('file', files[0].file)
      const uploadedFileUrl = await FileUploadApi.addImage(formData)

      if (uploadedFileUrl === '') {
        this.$bus.$emit('errorMessage', '이미지 등록에 실패하였습니다')
        return ''
      }
      // eslint-disable-next-line consistent-return
      return uploadedFileUrl
    },
    async getRegions() {
      this.regions = await getAddressList()
    },
    async onChangeRegion() {
      this.cities = await getAddressList(this.model.formItem.working_area_idx)
    },
    async addItem() {
      if (this.$refs.form.validate() === false) {
        return
      }
      this.model.formItem.profile_image = await this.uploadImage()
      const res = await this.model.add()
      if (res.result === true) {
        this.$bus.$emit('successMessage', '등록되었습니다')
        this.backToListPage()
      } else if (res.message === "Caregiver exist") {
        this.$bus.$emit('errorMessage', `이미 등록되어 있는 전화번호입니다.`)
      } else {
        this.$bus.$emit('errorMessage', '서버 통신에 문제가 발생했습니다')
      }
    },
    async updateItem() {
      if (this.$refs.form.validate() === false) {
        return
      }
      this.model.formItem.profile_image = await this.uploadImage()
      const result = await this.model.update()
      if (result === true) {
        this.$bus.$emit('successMessage', '수정되었습니다')
        this.backToListPage()
      } else {
        this.$bus.$emit('successMessage', '서버 통신에 문제가 발생했습니다')
      }
    },
    async deleteItem() {
      const result = await this.model.delete()
      if (result === true) {
        this.$bus.$emit('successMessage', '삭제되었습니다')
        this.backToListPage()
      } else {
        this.$bus.$emit('successMessage', '서버 통신에 문제가 발생했습니다')
      }
    },
    backToListPage() {
      const latestHistory = this.$store.getters['App/routerHistoryLatest']
      if (latestHistory.name === 'Carer.List') {
        this.$router.go(-1)
      } else {
        this.$router.push(`/carers?page=1`)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-data-table {
  ::v-deep table > thead > tr > th {
    background-color: #EEEEEE;
  }
}
.v-skeleton-loader {
  ::v-deep .v-skeleton-loader__list-item {
    padding: 0px !important;
  }
}
.filepond--wrapper {
  ::v-deep .filepond--credits {
    display: none;
  }
}
</style>
