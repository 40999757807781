import { Api } from '@/libraries/api'
// import { Api, makeGetUrl } from '@/libraries/api'
import { CarerSchema, CarerFormSchema } from './carerSchema'

const Carer = class {
  #idx = null

  constructor() {
    this.item = new CarerSchema()
    this.formItem = new CarerFormSchema()
  }

  set idx(idx) {
    this.#idx = idx
  }

  get idx() {
    return this.#idx
  }

  static exception(message) {
    console.error(message)
  }

  #setSchemaMapper() {
    const bi = this.item.basic_info
    const ei = this.item.extra_info
    this.formItem.age = bi.age
    // this.formItem.email = bi.email
    this.formItem.gender = bi.gender
    this.formItem.name = bi.name
    this.formItem.phone_number = bi.phone
    this.formItem.career = ei.career
    this.formItem.license = ei.license
    this.formItem.desired_salary = ei.desired_salary
    this.formItem.marketing_notification = ei.marketing_notification
    this.formItem.event_notification = ei.event_notification
    this.formItem.office_hour = ei.office_hour
    this.formItem.profile_image = ei.profile_image
    this.formItem.introduce = ei.introduce
    this.formItem.working_area_idx = ei.working_area_idx
    this.formItem.working_area_detail_idx = ei.working_area_detail_idx
  }

  getFormItem() {
    this.#setSchemaMapper()
    return this.formItem
  }

  formTypeConverter() {
    this.formItem.age = parseInt(this.formItem.age, 10)
    this.formItem.phone_number = this.formItem.phone_number.replaceAll('-', '')
    this.formItem.career = parseInt(this.formItem.career, 10)
    this.formItem.working_area_idx = parseInt(this.formItem.working_area_idx, 10)
    this.formItem.working_area_detail_idx = parseInt(this.formItem.working_area_detail_idx, 10)
    this.formItem.marketing_notification = parseInt(this.formItem.marketing_notification, 10)
    this.formItem.event_notification = parseInt(this.formItem.event_notification, 10)
  }

  async bindItem() {
    const url = `/admin/caregiver/${this.#idx}`
    const apiResult = await Api().get(url)
    const { item } = apiResult.data.data
    this.item = item
    this.#setSchemaMapper()
  }

  async isSetAlarm() {
    const url = `admin/alarm/caregiver/${this.#idx}`
    const apiResult = await Api().get(url)
    const { message } = apiResult.data
    return message
  }

  async add() {
    this.formTypeConverter()
    const url = `/admin/caregiver`
    const data = { ...this.formItem }

    const res = {
      result: true,
      message: ''
    }
    const apiResult = await Api().post(url, data).catch((error) => {
      if (error.response) {
        // 요청 성공 but 서버에서 에러 메세지를 내는 경우
        res.result = false
        res.message = error.response.data.detail
      } else if (error.request) {
        // 요청 성공, 응답 받지 못한 경우 (XMLHttpRequest 인스턴스 (in browser))
        res.result = false
        res.message = error.request
      } else {
        res.result = false
        res.message = error.message
      }
    })
    if (res.result === true) {
      const { result } = apiResult.data
      res.result = result
      res.message = 'success'
    }
    return res
  }

  async update() {
    const url = `/admin/caregiver/${this.#idx}`
    const data = { ...this.formItem }
    const apiResult = await Api().put(url, data)
    const { result } = apiResult.data
    return result
  }

  async delete() {
    const url = `/admin/caregiver/${this.#idx}`
    const apiResult = await Api().delete(url)
    const { result } = apiResult.data
    return result
  }
}

export default Carer
