<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <PageTitleComponent :title="`등록 간병인 정보 ${mode_text}`" />
        </v-col>
      </v-row>
      <v-row>
        <v-btn class="mx-4" @click="onClickCopy">
          복사하기
        </v-btn>
      </v-row>
      <v-row v-show="mode !== 'add'">
        <v-col cols="12" class="pb-0">
          <v-tabs v-model="tabModel" centered grow background-color="indigo lighten-4" slider-color="indigo darken-3">
            <v-tab key="tab1">
              간병인 정보
            </v-tab>
            <v-tab key="tab2">
              결제정보
            </v-tab>
            <v-tab key="tab3">
              지원목록
            </v-tab>
            <v-tab key="tab4">
              매칭완료목록
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="py-0">
          <v-tabs-items v-model="tabModel">
            <v-tab-item key="tab1" class="pb-8" style="background: #F3F3F3;">
              <ItemProfileComponent
                v-model="model"
                :working_area_idx="model.formItem.working_area_idx"
                :working_area_detail_idx="model.formItem.working_area_detail_idx"
              />
            </v-tab-item>
            <v-tab-item key="tab2" class="pb-8" style="background: #F3F3F3;">
              <ItemPaymentsComponent v-model="model" />
            </v-tab-item>
            <v-tab-item key="tab3" class="pb-8" style="background: #F3F3F3;">
              <ItemRequestsComponent v-model="model" request-type="apply" />
            </v-tab-item>
            <v-tab-item key="tab4" class="pb-8" style="background: #F3F3F3;">
              <ItemRequestsComponent v-model="model" request-type="done" />
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-row justify="center" class="mt-5">
        <v-col cols="auto">
          <v-btn color="" class="mx-4" @click="backToListPage">
            <v-icon small>
              list
            </v-icon>
            <span class="pl-2">목록으로</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Carer from './model/carer'
import PageTitleComponent from '../_elements/title/PageTitle'
import ItemProfileComponent from './ItemProfile'
import ItemPaymentsComponent from './ItemPayments'
import ItemRequestsComponent from './ItemRequests'

const locations = [
  { text: '전체', value: 1 },
  { text: '서울', value: 2 },
  { text: '부산', value: 3 },
  { text: '대구', value: 4 },
  { text: '인천', value: 5 },
  { text: '광주', value: 6 },
  { text: '대전', value: 7 },
  { text: '울산', value: 8 },
  { text: '세종', value: 9 },
  { text: '경기', value: 10 },
  { text: '강원', value: 11 },
  { text: '충북', value: 12 },
  { text: '충남', value: 13 },
  { text: '전북', value: 14 },
  { text: '전남', value: 15 },
  { text: '경북', value: 16 },
  { text: '경남', value: 17 },
  { text: '제주', value: 18 }
]

export default {
  components: {
    PageTitleComponent,
    ItemProfileComponent,
    ItemPaymentsComponent,
    ItemRequestsComponent
  },
  data() {
    return {
      model: new Carer(),
      carerIdx: null,
      tabModel: 'tab1',
      mode: '',
      mode_text: ''
    }
  },
  async created() {
    await this.setup()
  },
  methods: {
    async setup() {
      const { idx } = this.$route.params
      if (idx === 'add') {
        this.mode = 'add'
        this.mode_text = '등록'
      } else if (this.$route.name === 'Carer.Item') {
        this.mode = 'modify'
        this.mode_text = '확인, 수정'
        this.carerIdx = parseInt(this.$route.params.idx, 10)
        this.model.idx = this.carerIdx
        await this.model.bindItem()
      }
    },
    backToListPage() {
      const latestHistory = this.$store.getters['App/routerHistoryLatest']
      if (latestHistory.name === 'Carer.List') {
        this.$router.go(-1)
      } else {
        this.$router.push(`/carers?page=1`)
      }
    },
    async onClickCopy() {
      // index/이름/간병인_자원/나이/성별/경력/회원등급/자격증/지역/연락처/시간/급여/자격증/보험/매칭/지역/가입경로/주민등록번호/소속/특이사항
      const idx = this.model.item.extra_info.idx
      const name = this.model.item.basic_info.name
      const age = this.model.item.basic_info.age
      const gender = this.model.item.basic_info.gender
      const career = this.model.item.extra_info.career
      const grade = ''
      const license = this.model.item.extra_info.license
      const workingArea = locations.find(v => v.value === this.model.item.extra_info.working_area_idx).text
      const phone = this.model.item.basic_info.phone
      const officeHour = this.model.item.extra_info.office_hour
      const desiredSalary = this.model.item.extra_info.desired_salary

      const value = [
        idx,
        name,
        '',
        age,
        gender,
        career,
        grade,
        license,
        workingArea,
        phone,
        officeHour,
        desiredSalary,
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        ''
      ]
        .map(v => `"${v}"`)
        .join('\t')

      navigator.clipboard.writeText(value)
      this.$bus.$emit('successMessage', '복사완료')
    }
  }
}
</script>

<style lang="scss" scoped>
.v-data-table {
  ::v-deep table > thead > tr > th {
    background-color: #eeeeee;
  }
}
.v-skeleton-loader {
  ::v-deep .v-skeleton-loader__list-item {
    padding: 0px !important;
  }
}
.filepond--wrapper {
  ::v-deep .filepond--credits {
    display: none;
  }
}
</style>
