<template>
  <v-container>
    <v-row justify="end">
      <v-col cols="auto">
        <v-btn
          v-show="!isShowAddSection"
          small
          class="mx-0"
          color="primary"
          @click="isShowAddSection = !isShowAddSection"
        >
          <v-icon>add</v-icon>
          <span class="pl-2">수동 등록</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      v-show="isShowAddSection"
      no-gutters
    >
      <v-col>
        <v-container>
          <v-row>
            <v-col cols="12">
              <DatePickerRangeType2
                ref="data_picker_range_type2"
                :from.sync="formData.start_date"
                :to.sync="formData.end_date"
                start-date-ref="item__start_date"
                end-date-ref="item__end_date"
                start-date-label="시작날짜"
                end-date-label="종료날짜"
                start-date-append-icon="calendar_today"
                end-date-append-icon="calendar_today"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              class="text-center"
            >
              <v-btn
                class="mx-4"
                color="primary"
                @click="addCustomActivate"
              >
                등록하기
              </v-btn>
              <v-btn
                class="mx-4"
                @click="isShowAddSection = false"
              >
                등록취소
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-simple-table
          class="tbl elevation-2"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  v-for="item in headers"
                  :key="item.text"
                  :class="`text-${item.align}`"
                >
                  {{ item.text }}
                </th>
              </tr>
            </thead>
            <TbodyPreRender
              :is-loading="isLoading"
              :cols="headers.length"
              :items-length="items.length"
            />
            <tbody v-if="!isLoading">
              <tr
                v-for="item in items"
                :key="`carer-payments-item-${item.idx}`"
              >
                <td class="text-center">
                  {{ item.caregiver_payment.created_at | dayjsLocalDatetime }}
                </td>
                <td class="text-center">
                  {{ `${item.caregiver_payment.platform} (${item.caregiver_payment.payment_type})` }}
                  <!-- v-show="item.caregiver_payment.platform === 'pc'" -->
                  <v-btn
                    x-small
                    dark
                    depressed
                    color="red lighten-1"
                    @click="deleteItem(item.idx)"
                  >
                    삭제
                  </v-btn>
                </td>
                <td class="text-center">
                  {{ (item.caregiver_payment.platform === 'ios') ? `${$options.filters.numberFormat(item.service_pass.ios_cost)}`: `${$options.filters.numberFormat(item.service_pass.android_cost)}` }}
                </td>
                <td class="text-center">
                  {{ item.start_date | dayjsLocalDatetime }} ~ {{ item.end_date | dayjsLocalDatetime }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row class="mb-5">
      <v-col cols="12">
        <v-pagination
          v-model="model.pagination.page"
          class="pagination-circle"
          circle
          :length="model.pagination.length"
          :total-visible="model.pagination.totalVisible"
          prev-icon="keyboard_arrow_left"
          next-icon="keyboard_arrow_right"
          @input="getItems(model.pagination.page)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CarerPayments from './model/carerPayments'
import TbodyPreRender from '../_elements/table/TbodyPreRender'
import DatePickerRangeType2 from '../_elements/form/DatePickerRangeType2'
import Api from './model/api'

export default {
  components: {
    TbodyPreRender,
    DatePickerRangeType2
  },
  props: ['value'],
  data() {
    return {
      isLoading: false,
      isShowAddSection: false,
      carerModel: null,
      model: new CarerPayments(),
      items: [],
      headers: [
        { text: '결제일자', align: 'center' },
        { text: '결제수단', align: 'center' },
        { text: '결제금액', align: 'center' },
        { text: '활성기간', align: 'center' }
      ],
      formData: {
        start_date: '',
        end_date: ''
      }
    }
  },
  created() {
    this.carerModel = this.value
    this.model.idx = this.carerModel.idx
    this.getItems(1)
  },
  methods: {
    async addCustomActivate() {
      const resultObj = await this.model.addCustomActivate(
        `${this.formData.start_date} 00:00:00`,
        `${this.formData.end_date} 23:59:59`
      )
      if (resultObj.result === false) {
        this.$bus.$emit('errorMessage', resultObj.message)
      } else {
        this.$bus.$emit('successMessage', '등록되었습니다')
        this.isShowAddSection = false
        await this.getItems(1)
      }
    },
    async getItems(page) {
      this.isLoading = true
      this.model.pagination.page = page
      const { items, pagination } = await this.model.getList()
      if (items) {
        this.items = items
        this.model.pagination = { ...pagination } // vue 2 not catch deep object
      }
      this.isLoading = false
    },
    async deleteItem(idx) {
      const result = await Api.deletePass(idx)
      if (result === true) {
        this.$bus.$emit('successMessage', '삭제되었습니다')
        this.getItems(1)
      } else {
        this.$bus.$emit('errorMessage', '삭제할 수 없습니다')
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
