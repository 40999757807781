/* eslint-disable max-classes-per-file */
import { Api, makeGetUrl } from '@/libraries/api'
import paginationLib from '../../_elements/pagination/paginationLib'

const CarerPayments = class {
  #idx = null

  constructor() {
    this.query = ''
    // this.pagination = new Pagination()
    this.pagination = {}
  }

  set idx(idx) {
    this.#idx = idx
  }

  async getList() {
    const url = `/admin/pass/payment`
    const params = {
      page: this.pagination.page,
      limit: this.pagination.limit,
      query: this.query,
      filters: `caregiver(${this.#idx})`
    }
    const apiResult = await Api().get(makeGetUrl(url, params))
    const { items, pagination } = apiResult.data.data
    return {
      items,
      pagination: paginationLib.paginationMapper(pagination)
    }
  }

  async addCustomActivate(startDate, endDate) {
    const url = `/admin/pass/caregiver/${this.#idx}`
    const data = {
      start_date: startDate,
      end_date: endDate
    }
    const res = {
      result: true,
      message: ''
    }
    const apiResult = await Api().post(url, data).catch((error) => {
      if (error.response) {
        // 요청 성공 but 서버에서 에러 메세지를 내는 경우
        res.result = false
        res.message = error.response.data.detail
      } else if (error.request) {
        // 요청 성공, 응답 받지 못한 경우 (XMLHttpRequest 인스턴스 (in browser))
        res.result = false
        res.message = error.request
      } else {
        res.result = false
        res.message = error.message
      }
    })
    if (res.result === true) {
      const { result } = apiResult.data
      res.result = result
      res.message = 'success'
    }
    return res
  }
}

export default CarerPayments
